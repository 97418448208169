body {
  background: rgba(200, 200, 200, 0.5);
}

.Container {
  margin: auto;
  width: 1200px;
  border: 1px solid #aaa;
  background: #fff;
  padding: 50px;
  margin-top: 20px;
}